import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Spinner from "./components/Spinner";

// Containers
const DefaultLayout = React.lazy(() => import("./layouts/DefaultLayout"));

// Pages
const Page404 = React.lazy(() => import("./pages/Page404"));
const Page500 = React.lazy(() => import("./pages/Page500"));

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Spinner />}>
        <Routes>
          <Route exact path="/500" element={<Page404 />} />
          <Route exact path="/500" element={<Page500 />} />
          <Route path="*" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
